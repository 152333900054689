<template>
  <div style="background-color: white;">
    <pre>{{ contents }}</pre>
  </div>
</template>

<script>
  import { checkIban } from '../functions'

  export default {
    components: { 
    },
    data () {
      return {
        contents: ''
      }
    },
    props: {
      beneficiaryCode: String
    },
    mounted () {

      // Get Provider Adress
      this.$http.post('/accounting/send-notifications', {

      "accountingPeriodId": 2401,
      "beneficiaryCode": this.beneficiaryCode

      })
        .then(response => {
          this.contents = response.data
        })
    }
  }
</script>